import Footer from "../components/Footer";
import Header from "../components/Header";
import rabbit from "../assets/img/videos/Rabbit.png"
import tiger from "../assets/img/videos/Tiger.png"
import elephants from "../assets/img/videos/Elephants.png"
import jhirnatiger from "../assets/img/videos/JhirnaTiger.png"
import dogs from "../assets/img/videos/Dogs.png"
import elephants2 from "../assets/img/videos/elephants2.png"
import Bisen from "../assets/img/videos/Bisen.png"
import BearCubs from "../assets/img/videos/BearCubs.png"
import ElephantFam from "../assets/img/videos/ElephantFam.png"
import ElephantWild from "../assets/img/videos/ElephantWild.png"
import kaziranga from "../assets/img/videos/kaziranga.png"
import kobra from "../assets/img/videos/kobra.png"
import LoneElephant from "../assets/img/videos/LoneElephant.png"
import Rhino from "../assets/img/videos/Rhino.png"
import TigerCub from "../assets/img/videos/TigerCub.png"
import Tigress from "../assets/img/videos/Tigress.png"

export default function Gallery(){
    var videoUrls = [
        {
            'image':rabbit,
            'link':'https://www.youtube.com/watch?v=bgEqobENsbI'//Rabbit
        },
        {
            'image':tiger,
            'link':'https://www.youtube.com/watch?v=eXZfZbA87Ns'//Tiger
        },
        {
            'image':elephants2,
            'link':'https://www.youtube.com/watch?v=wXXABSFR3DI'//Elephant
        },
        {
            'image':jhirnatiger,
            'link':'https://www.youtube.com/watch?v=fKAOhTuQcG8'//Tiger Jhirna
        },
        {
            'image':elephants,
            'link':'https://www.youtube.com/watch?v=d0jSV-9KRNg'//Elephats Dhikala
        },
        {
            'image':dogs,
            'link':'https://www.youtube.com/watch?v=ZW3Lyg5UPNk'//Dogs
        },
        {
            'image':Bisen,
            'link':'https://www.youtube.com/watch?v=S31DvNUaTTk'//Elephats Dhikala
        },
        {
            'image':BearCubs,
            'link':'https://www.youtube.com/watch?v=dvQnLM-gelg'//Elephats Dhikala
        },
        {
            'image':ElephantFam,
            'link':'https://www.youtube.com/watch?v=JrOELEsdIVs'//Elephats Dhikala
        },
        {
            'image':ElephantWild,
            'link':'https://www.youtube.com/watch?v=YHHvFgJMqQg'//Elephats Dhikala
        },
        {
            'image':kaziranga,
            'link':'https://www.youtube.com/watch?v=ww57bYKDL1Q'//Elephats Dhikala
        },
        {
            'image':kobra,
            'link':'https://www.youtube.com/watch?v=8LDBgHpZnPE'//Elephats Dhikala
        },
        {
            'image':LoneElephant,
            'link':'https://www.youtube.com/watch?v=bX7Fh3cPz88'//Elephats Dhikala
        },
        {
            'image':Rhino,
            'link':'https://www.youtube.com/watch?v=6fNkAclZuBg'//Elephats Dhikala
        },
        {
            'image':TigerCub,
            'link':'https://www.youtube.com/watch?v=rJU6oSNINDk'//Elephats Dhikala
        },
        {
            'image':Tigress,
            'link':'https://www.youtube.com/watch?v=v2RSyPbUnB0'//Elephats Dhikala
        },
    ]
    return(
        <>
            <Header/>
            <h1 className="font-bold text-3xl text-center mt-10 mb-10">Youtube Gallery</h1>
            <div className="grid gap-5 grid-col-1 lg:grid-cols-3 mb-20 lg:p-10">
                {
                    videoUrls.map((e,index)=>{
                        return  <a href={e.link}><div className="bg-black"><img className="aspect-[4/3]" src={e.image}></img></div></a>
                    })
                }
            </div>
            <Footer/>
        </>
    )
}