import Header from "../components/Header";
import Footer from "../components/Footer"
import contact from "../assets/img/bg_contact.jpg"
import { useState } from "react";
import emailjs from 'emailjs-com';

export default function Contact(){
    const [contactValue,setContact] = useState(false);
    const [handleEmail,setHandleEmail] = useState(false);
    const [handleName,setHandleName] = useState(false);
    const [handlePhone,setHandlePhone] = useState(false);
    const [handleQuery,setHandleQuery] = useState(false);

    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState("");
    const [query,setQuery] = useState("");

    const handlesetQuery = (e)=>{
        setQuery(e.target.value)
    }

    const handlesetContact = ()=>{
        setContact(!contactValue)
    }

    const handlesetEmail = (e)=>{
        setEmail(e.target.value)
    }

    const handlesetName = (e)=>{
        setName(e.target.value)
    }

    const handlesetPhone = (e)=>{
        setPhone(e.target.value)
    }
    const submitForm = (e)=>{
        e.preventDefault();

        if(name != '' && email != '' && phone != '' && query != ''){
            e.preventDefault();
            setHandleName(false);
            setHandleEmail(false);
            setHandlePhone(false);
            setHandleQuery(false);
            handlesetContact();
            emailjs.sendForm('service_5ceqmfo', 'template_qtnos1n', e.target, '6uY8Dln-vInO8K49c')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        }

        else if(name == ''){
            setHandleName(true);
        }

        else if(email == ''){
            setHandleEmail(true);   
        }

        else if(phone == ''){
            setHandlePhone(true);
        }
        else if(query == ''){
            setHandleQuery(true);
        }
    }
    return(
        <div>
            <Header/>
            <div className="mb-10">
                <div className="center p-4 w-full lg:p-10 lg:ml-[28%] lg:w-[42%] lg:mr-[28%] absolute bg-white rounded-lg Shadow z-10 mt-24">
                    <h1 className="font-bold text-3xl text-center">Contact Us</h1>
                    <form onSubmit={submitForm}>
                        <div className="mt-10">
                            <h1 className="font-normal w-full text-md text-left mb-4 mt-4" aria-required>Name*</h1>
                            { handleName ? <p className="text-red-500 text-lg">Please enter your name</p>:null}
                            <input name="name" value={name}  onChange={handlesetName} type="text"placeholder="Name*" className="w-full rounded-md p-2 border-2"></input>
                            <h1 className="font-normal w-full text-md text-left mb-4 mt-4">Email*</h1>
                            { handleEmail ? <p className="text-red-500 text-lg">Please enter your email</p>:null}
                            <input name="email" value={email} onChange={handlesetEmail} type="text"placeholder="Email*" className="w-full rounded-md p-2 border-2"></input>
                            
                            <h1 className="font-normal w-full text-md text-left mb-4 mt-4">Mobile Number*</h1>
                            { handlePhone ? <p className="text-red-500 text-lg">Please enter your mobile number</p>:null}
                            <input name="phone" value={phone} onChange={handlesetPhone} type="text"placeholder="Mobile Number*" className="w-full rounded-md p-2 border-2"></input>
                            <h1 className="font-normal w-full text-md text-left mb-4 mt-4">Query*</h1>
                            {handleQuery ? <p className="text-red-500 text-lg">Please enter your query</p>: null}
                            <textarea name="query" value={query} onChange={handlesetQuery} type="text" className="h-[200px] rounded-md p-2 border-2 w-full"></textarea>
                            {
                                contactValue ?  <p type="button" className="w-full text-center text-black">Thanks for contacting us, looking forward to serve you in the best possible way!</p>   :
                                <button type="submit" className="w-full bg-blue-400 p-2 text-center text-white mt-5 rounded-lg hover:bg-blue-600">Contact Now</button>    
                            }
                        </div>
                    </form>
                </div>
                <img className="w-full" src={contact}></img>
            </div>
            <Footer/>
        </div>
    )
}