import { Box } from '@mui/system';
import logo from '../assets/img/logo.png';

export default function Footer(){
    return (
        <Box>
            <footer className="w-full bottom-0  pl-10 pr-10 lg:pl-40 lg:pr-40 bg-white dark:bg-gray-900">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <a href="/" className="flex items-center mb-4 sm:mb-0">
                    <img src={logo} className="w-20"></img>
                        {/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span> */}
                    </a>
                    <ul className="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
                        <li>
                            <a href="/about" className="mr-4 hover:underline md:mr-6 ">About</a>
                        </li>
                        <li>
                            <a href="/privacy" className="mr-4 hover:underline md:mr-6">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/contact" className="hover:underline">Contact</a>
                        </li>
                    </ul>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"></hr>
                <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="" className="hover:underline">Safaris™</a>. All Rights Reserved.
                </span>
            </footer>
        </Box>
            
        )
    }