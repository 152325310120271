import CallIcon from '@mui/icons-material/Call';
import { Grid } from "@mui/material";
import contact from '../assets/img/contact.png';
import ratings from '../assets/img/ratings.png';
import verified from '../assets/img/verified.png';
export default function ContactInfo(){
    return (
        <div className='mt-10 mb-20 mx-4 lg:mx-36 bg-white text-center rounded-lg Shadow p-10 border-black'>
            <Grid container>
                <Grid item lg={4} xs={12}>
                    <img src={ratings} className="block m-auto"></img>
                    <h1 className='text-md'>Top Rated Safaris</h1>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <img src={verified} className="block m-auto"></img>
                    <h1 className='text-md'>Verified Listings</h1>
                </Grid>
                <Grid item lg={4} xs={12}>
                    <a href="/contact/"><img src={contact} className="block m-auto"></img>
                    <h1 className='text-md'>Contact</h1></a>
                </Grid>
            </Grid>
        </div>
    )
}