import { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import EmailIcon from '@mui/icons-material/Email';
import logo from '../assets/img/logo.png';
import { Grid } from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function Header() {
  const [openNav, setOpenNav] = useState(false);
 
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
 
  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-6 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-md"
      >
        <a href="/about" className="flex items-center">
          About Us
        </a>
      </Typography>

      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-md"
      >
        <a href="/parks" className="flex items-center">
          Explore Parks
        </a>
      </Typography>

      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-md"
      >
        <a href="/gallery" className="flex items-center">
          Gallery
        </a>
      </Typography>

      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-md"
      >
        <a href="/gift" className="flex items-center">
          Gift Shop
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-md"
      >
        <a href="/testimonials" className="flex items-center">
          Testimonials
        </a>
      </Typography>

      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-md"
      >
        <a href="https://www.wildlifenews.co.in" target="_blank" className="flex items-center">
          News from the wild
        </a>
      </Typography>

      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal text-md"
      >
        <a href="/contact" className="flex items-center">
          Contact
        </a>
      </Typography>
    </ul>
  );
 
  return (
    <div>
      <div id="announcement" className="w-full bg-black p-2">
        <Grid container className="text-center align-middle">
          <Grid item lg={3} xs={12} className="align-middle">
            <EmailIcon className="text-md text-white"/> <a href="mailto:travelindiasafaris@travelindiasafaris.com"className="text-center text-white text-md">travelindiasafaris@travelindiasafaris.com</a>
          </Grid>
          <Grid item lg={3} xs={12} className="align-middle">
            <LocalPhoneIcon className="text-md text-white"/> <a href="tel:+919711839999"className="text-white text-center text-md"> Call : +91 9711839999</a>,<a href="tel:+919999687225"className="text-white text-center text-md"> +91 9999687225</a>
          </Grid>
          <Grid item lg={3} xs={12} className="align-middle">
            <a className="text-center text-white text-md"><LocationOnIcon className="text-md text-white"/>  Ramnagar, Uttarakhand, India</a>
          </Grid>
          <Grid item lg={3} xs={12} className="align-middle">
            <p className="text-white text-center text-md"> GSTIN : 05AFPPD1859N1Z1</p>
          </Grid>
        </Grid>
      </div>
    
      <div className="p-5 lg:pl-40 lg:pr-40 Shadow">
        <div className="container flex items-center justify-between text-blue-gray-900">
          <a href="/"><img src={logo} className="w-20"></img></a>
          <div className="hidden lg:block">{navList}</div>
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
        <MobileNav open={openNav}>
          {navList}
        </MobileNav>
      </div>
    </div>
  );
}