import { Box } from "@mui/system"
import Header from "../components/Header"
import bg from '../assets/img/mainBg.jpeg'
import { useNavigate } from "react-router-dom";
import meghlat from "../assets/img/meghlat.jpeg"
import tadoba from '../assets/img/tiger.jpeg'
import tadoba_ from '../assets/img/tadoba_.jpeg'
import ranthambore from "../assets/img/ranthambore.jpeg"
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Input,
    Button,
    Checkbox,
    Select,
    Option
  } from "@material-tailwind/react";

import panna from "../assets/img/panna.jpeg";

import { useRef, useState } from "react";
import Footer from "../components/Footer";
import { Grid } from "@mui/material";
import ContactInfo from "../components/ContactInfo";
import YoutubeGallery from "../components/youtubeGallery";

export default function Home(){
    const navigate = useNavigate();
    const [trip,setTrip] = useState("0");
    const [selectPark,setSelectPark] = useState(false);

    const myRef1 = useRef(null)
    const myRef2 = useRef(null)
    const myRef3 = useRef(null)
    const myRef4 = useRef(null)
    const myRef5 = useRef(null)
    const myRef6 = useRef(null)
    const myRef7 = useRef(null)
    const myRef8 = useRef(null)
    const myRef9 = useRef(null)
    const myRef10 = useRef(null)

    const theme = {
        radio: {
          styles: {
            root: {
              color: "white",
            },
          },
        },
    };

    function onChangeTrip(tripValue){
        setTrip(tripValue);
    }

    const ParkArr = [{ref:myRef1,name:'Jim Corbett National Park',img:tadoba},
                    {ref:myRef2,name:'Panna National Park',img:panna},
                    {ref:myRef3,name:'Bandhavgarh National Park',img:tadoba},
                    {ref:myRef4,name:'Ranthambore National Park',img:ranthambore},
                    {ref:myRef5,name:'Kanha National Park',img:tadoba},
                    {ref:myRef6,name:'Melghat National Park',img:meghlat},
                    {ref:myRef7,name:'Tadoba National Park',img:tadoba_},
                    {ref:myRef8,name:'Sariska Tiger Reserve',img:tadoba},
                    {ref:myRef9,name:'Pilibhit Tiger Reserve',img:tadoba},
                    {ref:myRef10,name:'Dudhwa Tiger Reserve',img:tadoba}]

    const SubmitForm = (e)=>{
        e.preventDefault();
        if(trip == 0){
            setSelectPark(true);
        }else{
            setSelectPark(false);
            if(trip == 1){
                myRef1.current.scrollIntoView();
            }else if(trip == 2){
                myRef2.current.scrollIntoView();
            }else if(trip == 3){
                myRef3.current.scrollIntoView();
            }else if(trip == 4){
                myRef4.current.scrollIntoView();
            }else if(trip == 5){
                myRef5.current.scrollIntoView();
            }else if(trip == 6){
                myRef6.current.scrollIntoView();
            }else if(trip == 7){
                myRef7.current.scrollIntoView();
            }else if(trip == 8){
                myRef8.current.scrollIntoView();
            }else if(trip == 9){
                myRef9.current.scrollIntoView();
            }else if(trip == 10){
                myRef10.current.scrollIntoView()
            }
        }
        
    }

    
    return(
        <Box className="w-full">
            <Header/>
            <Box className="w-full ">
                <form onSubmit={SubmitForm}>
                <Box className="w-full h-full lg:pl-[28%] lg:pr-[28%] center">
                    <Box className="mt-4 lg:mt-28 Shadow lg:w-[42%] absolute center bg-black z-10 p-5 rounded-xl content-center bg-opacity-50">
                        <h1 className="text-white font-normal w-full text-xl mb-10 text-center">Plan your Jungle Safari</h1>
                        <Grid container className="center mb-5 text-center" spacing={2}>
                            <Grid item lg={12}>
                                <label className="text-white text-md text-center font-bold">Indian Wildlife : National Parks & Tiger Reserves</label>
                            </Grid>
                        </Grid>
                        <h1 className="text-white font-normal w-full text-md text-left">Select Park Name</h1>
                        {
                            selectPark ? <p className="text-center text-red-500 text-lg bg-white">Please Select Park..</p>:null
                        }
                        <div className="w-full mt-5 mb-5">
                            <Select label="Park Name" onChange={onChangeTrip} name="park" className="bg-white">
                                <Option value="1">Jim Corbett National Park, Ramnagar, Uttarakhand, India</Option>
                                <Option value="2">Panna National Park, Panna Khajuraho,Madhya Pradesh,India</Option>
                                <Option value="3">Bandhavgarh National Park, Umaria,Madhya Pradesh, India</Option>
                                <Option value="4">Ranthambore National Park, Sawai Madopur, Rajasthan, India</Option>
                                <Option value="5">Kanha National Park, Balaghat, Madhya Pradesh, India</Option>
                                <Option value="6">Melghat National Park, Akola, Maharashtra, India</Option>
                                <Option value="7">Tadoba National Park, Chandrapur, Maharashtra, India</Option>
                                <Option value="8">Sariska Tiger Reserve, Alwar, Rajasthan, India</Option>
                                <Option value="9">Pilibhit Tiger Reserve, Pilibhit City, Uttar Pradesh, India</Option>
                                <Option value="10">Dudhwa Tiger Reserve, Palia Kalan, Uttar Pradesh, India</Option>
                            </Select>
                        </div>

                        <Grid container spacing={3} className="mb-5">
                            <Grid item>
                                <h1 className="text-white font-normal w-full text-left mb-2">Check In</h1>
                                <input type="date" className="rounded-md p-2"></input>
                            </Grid>
                            <Grid item>
                                <h1 className="text-white font-normal w-full text-md text-left mb-2">Check Out</h1>
                                <input type="date" className="rounded-md p-2"></input>
                            </Grid>
                            <Grid item>
                                <h1 className="text-white font-normal w-full text-md text-left mb-2">Travellers</h1>
                                <Select label="Select" className="bg-white">
                                    <Option>2 Travellers, 1 Room</Option>
                                    <Option>4 Travellers, 2 Rooms</Option>
                                    <Option>6 Travellers, 3 Rooms</Option>
                                    <Option>8 Travellers, 4 Rooms</Option>
                                    <Option>10 Travellers, 5 Rooms</Option>
                                </Select>
                            </Grid>
                        </Grid>

                        <button type="submit" className="mb-5 inline-block bg-blue-500 rounded-md p-3 text-white center w-full normal-case font-normal text-md">Travel Guide</button>
                    </Box>
                </Box>
                </form>
                
                <img src={bg} className="w-full h-[700px]"></img>
            </Box>
            
            <YoutubeGallery/>
            <div className="mt-10 mx-4 lg:mx-36 bg-white">
                <h1 className="text-3xl font-bold text-center mb-14 mt-14">Explore top Indian National Parks & Tiger Reserves</h1>
                <div className="grid gap-2 grid-col-1 lg:grid-cols-3">
                    {
                        ParkArr.map((e,index)=>{
                            return <a key={index + 1} ref={e.ref} href={"/parks/" + (index + 1)}>
                            <div className="relative">
                                <div className="bg-black"><img className="aspect-[4/3]" src={e.img}></img></div>
                                <h1 className="absolute text-2xl text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">{e.name}</h1>
                            </div>
                        </a>
                        })
                    }
                </div>
            </div>
            <ContactInfo/>
            <Footer/>
        </Box>
    )
}