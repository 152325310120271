import Header from "../components/Header"

export default function Gift() {
    return (
        <>
            <Header />
            <h1 className="text-center text-xl mt-10 font-bold">Gift Shop</h1>
            <div className="flex justify-center mt-5">
                <table className="border border-collapse border-black">
                    <thead>
                        <tr>
                            <th className="border p-2">Product</th>
                            <th className="border p-2">MRP</th>
                            <th className="border p-2">Discount Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border p-2">Sleeping Bag Basic</td>
                            <td className="border p-2">Rs.1200</td>
                            <td className="border p-2">Rs.800</td>
                        </tr>
                        <tr>
                            <td className="border p-2">Sleeping Bag Basic1</td>
                            <td className="border p-2">Rs.1499</td>
                            <td className="border p-2">Rs.1000</td>
                        </tr>
                        <tr>
                            <td className="border p-2">Sleeping Bag Advanced</td>
                            <td className="border p-2">Rs.2999</td>
                            <td className="border p-2">Rs.1499</td>
                        </tr>
                        <tr>
                            <td className="border p-2">Fancy Half Sleeve Shirts</td>
                            <td className="border p-2">Rs.699</td>
                            <td className="border p-2">Rs.399</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p className="text-center mt-5">All Prices inclusive of GST. Delivery Charges may apply for remote locations.</p>
        </>
    )
}
