import Parks from "../components/ExploreParks";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function ExploreParks(){
    return(
        <>
        <Header/>
        <Parks/>
        <Footer/>
        </>
    )
}