import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './pages/home';
import About from './pages/about'
import PrivacyPolicy from './pages/privacypolicy'
import { ThemeProvider } from "@material-tailwind/react";
import React from 'react';
import './assets/css/index.css';
import Contact from './pages/contact';
import Testimonials from './pages/testimonial';
import ExploreParks from './pages/ExploreParks';
import Gallery from './pages/gallery';
import Booking from './pages/Booking';
import News from "./pages/news"
import Gift from "./pages/gift"

const root = ReactDOM.createRoot(document.getElementById("root"));
 
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Router>
        <Routes>
          <Route exact path="/news" element={<News/>}/>
          <Route exact path="/gift" element={<Gift/>}/>
          <Route exact path="/parks/:id" element={<Booking/>}/>
          <Route exact path="/parks" element={<ExploreParks/>}/>
          <Route exact path="/gallery" element={<Gallery/>}/>
          <Route exact path="/contact" element={<Contact/>}/>
          <Route exact path="/privacy" element={<PrivacyPolicy/>}/>
          <Route exact path="/testimonials" element={<Testimonials/>}/>
          <Route exact path="/about" element={<About />}/>
          <Route exact path="/" element={<Home/>}/>
        </Routes>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);