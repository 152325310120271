import panna from "../assets/img/panna.jpeg";
import meghlat from "../assets/img/meghlat.jpeg"
import tadoba from '../assets/img/tiger.jpeg'
import tadoba_ from '../assets/img/tadoba_.jpeg'
import ranthambore from "../assets/img/ranthambore.jpeg"

export default function Parks(){

    const ParkArr = [{name:'Jim Corbett National Park',img:tadoba},
                    {name:'Panna National Park',img:panna},
                    {name:'Bandhavgarh National Park',img:tadoba},
                    {name:'Ranthambore National Park',img:ranthambore},
                    {name:'Kanha National Park',img:tadoba},
                    {name:'Melghat National Park',img:meghlat},
                    {name:'Tadoba National Park',img:tadoba_},
                    {name:'Sariska Tiger Reserve',img:tadoba},
                    {name:'Pilibhit Tiger Reserve',img:tadoba},
                    {name:'Dudhwa Tiger Reserve',img:tadoba}]

    return(
    <div className="mt-10 mx-4 lg:mx-36 bg-white">
        <h1 className="text-3xl font-bold text-center mb-14 mt-14">Explore top Indian National Parks & Tiger Reserves</h1>
        <div className="grid gap-2 grid-col-1 lg:grid-cols-3 mb-10">
            {
                ParkArr.map((e,index)=>{
                    return <a ref={e.ref} href={"/parks/" + (index + 1)}>
                    <div className="relative">
                        <div className="bg-black"><img className="aspect-[4/3]" src={e.img}></img></div>
                        <h1 className="absolute text-2xl text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">{e.name}</h1>
                    </div>
                </a>
                })
            }
        </div>
    </div>
    )
}