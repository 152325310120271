import Footer from "../components/Footer";
import Header from "../components/Header";
import contact from "../assets/img/bg_contact.jpg"

export default function Testimonials(){
    return(
        <div>
            <Header/>
            <div className="mb-10">
                <div className="center p-4 w-full lg:p-10 lg:ml-[28%] lg:w-[42%] lg:mr-[28%] absolute bg-white rounded-lg Shadow z-10 mt-10">
                    <h1 className="font-bold text-3xl text-center">Write down your experience with us!</h1>
                    <div className="mt-5">
                        <h1 className="font-normal w-full text-md text-left mb-4 mt-4">Name*</h1>
                        <input type="text"placeholder="Name*" className="w-full rounded-md p-2 border-2"></input>
                        <h1 className="font-normal w-full text-md text-left mb-4 mt-4">Email*</h1>
                        <input type="text"placeholder="Email*" className="w-full rounded-md p-2 border-2"></input>
                        <h1 className="font-normal w-full text-md text-left mb-4 mt-4">Mobile Number*</h1>
                        <input type="text"placeholder="Mobile Number*" className="w-full rounded-md p-2 border-2"></input>
                        <h1 className="font-normal w-full text-md text-left mb-4 mt-4">Your Experience*</h1>
                        <input type="text"placeholder="Write down your experience*" className="w-full rounded-md p-2 border-2"></input>
                        <a type="button" className="w-full bg-blue-400 p-2 text-center text-white mt-5 rounded-lg hover:bg-blue-600">Send</a>
                    </div>
                </div>
                <img className="h-[700px] w-full" src={contact}></img>
            </div>
            <Footer/>
        </div>
    )
}