import Footer from "../components/Footer"
import Header from "../components/Header"
export default function About(){
    return(
        <div>
            <Header/>
            <h1 className="text-center font-bold text-4xl mt-10">About</h1>
            <div className="p-4 lg:pl-48 lg:pr-48 text-xl mb-14">
                <p>
                    Gaurav Dhanda is a wildlife enthusiast and conservationist having a Certificate in Naturalist training from Melghat Tiger Reserve. <br/>
                    Gaurav has been in love with the wild since he was 10 years old when he traveled to Bandhavgarh and was lucky to spot a tiger on elephant safari in the wild and a tiger cub whose mother had died which was being raised by the forest staff and was a great sight. 
                    Gaurav has traveled to 18 wilderness Reserves in India and wants to share his experiences and also assist Tourists in planning their safari across National Parks and Tiger Reserves of India.
                    Every trip is different and has different challenges for tourists. <br/>
                    <br/>From planning where to stay and which entry gate to take for a memorable experience is extremely important to have the best wilderness experience and sighting.
                    Gaurav is well connected with local guides and Naturalists and gypsy drivers across Reserves of North and Central India for an unmatched safari experience.
                    <br/><br/>Pet friendly resorts also available in Corbett Tiger Reserve and Panna Tiger Reserve.
                    <br/><br/>Hence get in touch on our contact page of <a href="https://www.travelindiasafaris.com" className="text-blue-800">www.travelindiasafaris.com </a> 
                    immediately.
                </p>
            </div>
            <Footer/>
        </div>
    )
}
